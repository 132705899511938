import { useEffect, useState } from 'react'
import {
  WhatsAppBackground,
  WhatsAppInfo,
  WhatsAppInfoContent,
  WhatsAppInfoTitle,
  WhatsAppSection,
  WhatsappStep,
} from './styles'

export function WhatsApp({ setLoad }) {
  const [empCode, setEmpCode] = useState(null)

  console.log(empCode)

  useEffect(() => {
    const empCode = sessionStorage.getItem('ASS_COD')
    setEmpCode(empCode.toLowerCase())
  }, [])

  return (
    <WhatsAppBackground>
      <WhatsAppSection>
        <section>
          {empCode && (
            <img
              src={`https://endorvistoria.com.br:21463/v1/wpp/connect/${empCode}`}
              alt=""
            />
          )}
        </section>
        <div>
          <h4>Conecte seu WhatsApp</h4>
          <p>Envie mensagens via Automação</p>
        </div>
      </WhatsAppSection>
      <WhatsAppInfo>
        <WhatsAppInfoTitle>
          <h2>Conecte seu WhatsApp</h2>
          <p>
            O Kamino pode utilizar o seu WhatsApp e enviar automaticamente
            mensagens para suas Oportunidades por meio das Automações!
          </p>
        </WhatsAppInfoTitle>
        <WhatsAppInfoContent>
          <h2>Veja como configurar:</h2>
          <WhatsappStep>
            <li>1. Abra o WhatsApp em seu Celular;</li>
            <li>
              2. Toque em <strong>Mais Opções</strong> no Android, ou em{' '}
              <strong>Configurações</strong> no iPhone;
            </li>
            <li>
              3. Toque em <strong>Dispositivos Conectados</strong> e, em{' '}
              seguida, toque em <strong>Conectar dispositivo</strong>;
            </li>
            <li>
              4. Por fim, aponte seu celular para esta tela para escanear o{' '}
              <strong>QR Code</strong> que está ao lado!
            </li>
          </WhatsappStep>
        </WhatsAppInfoContent>
      </WhatsAppInfo>
    </WhatsAppBackground>
  )
}
