import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const TabChange = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;

  margin-top: 50px;
  margin-bottom: 14px;

  border-bottom: 0.2px solid #e2e2e2;

  > span {
    margin-bottom: 7px;
    color: #6d6d6d;
    cursor: pointer;
  }

  .isActive {
    color: #0049c4;
    font-weight: 600;
    padding-bottom: 2px;
    border-bottom: solid 1px #0049c4;
  }
`

export const ImpressoesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  box-sizing: border-box;
`

export const ImpressoesSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
`

export const ImpressaoCard = styled.div`
  display: flex;
  gap: 15px;
  height: 98px;
  width: 100%;
  padding: 17px 17px 17px 17px;
  background-color: #f0f0f0;
  box-sizing: border-box;

  border-radius: 10px;

  h3 {
    margin: 0 0 4px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: #838387;
  }

  /* .checkbox {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #6d6d6d;
    background-color: white;
    appearance: none;
  } */

  /* .checkbox_active {
    appearance: none;
    margin: 0;
    padding: 0;
  } */
`
export const ImpressaoActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f0f0f0;
    font-size: 16px;
    color: #3a3a3a;

    gap: 4px;

    border-radius: 10px;
    font-weight: 500;
    width: 257px;
    height: 44px;
    > img {
      opacity: ${({ checkbox }) => (checkbox ? '1' : '0.3')};
    }

    > span {
      opacity: ${({ checkbox }) => (checkbox ? '1' : '0.3')};
    }
    cursor: ${({ checkbox }) => (checkbox ? 'pointer' : 'not-allowed')};
  }

  > label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f0f0f0;
    font-size: 14px;

    color: #3a3a3a;

    border-radius: 10px;

    font-weight: 500;

    width: 257px;
    height: 44px;
    cursor: ${({ checkbox }) => (checkbox ? 'pointer' : 'not-allowed')};

    border-radius: 10px;
  }

  .alter {
    color: #0049c4;
  }
`

export const ImpressionWaitActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #f0f0f0;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 500;

  box-sizing: border-box;

  width: 257px;

  border-radius: 10px;

  > img {
    width: 21px;
    height: 17px;
  }

  .rotate {
    animation: ${spin} 2s linear infinite;
  }
`

export const CheckboxParamter = styled.div`
  height: 100%;
  display: flex;

  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  > input {
    width: 0.75rem;
    height: 0.75rem;

    appearance: none;
    border-radius: 0.25rem;
    transition-duration: 0.3s;
    cursor: pointer;

    outline: solid 1px #00b065;
    border: 2px solid #fff;
    background-color: #fff;

    :checked {
      border: 2px solid #fff;
      background-color: #00b065;
    }

    :active {
      border: 2px solid #00b065;
    }

    :checked:focus {
      border: 2px solid #fff;
      outline: solid 1px #00b065;
    }

    :not(:checked):focus {
      outline: solid 1px #000;
    }

    :not(:checked) {
      outline: 1px solid #000;
    }
  }
`

export const ProgressBar = styled.div`
  height: 7px;
  background-color: #0049c4;
  border-radius: 0px 10px 10px 10px;

  border-top-right-radius: ${({ progress }) =>
    progress === 100 ? '0px' : '10px'};

  width: ${({ progress }) => `${progress}%`};
  transition: width 0.2s ease;
`

export const InfoAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 257px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 4px;

    opacity: ${({ checkbox }) => (checkbox ? '1' : '0.3')};
  }

  > img {
    width: 21px;
    height: 17px;
  }

  .rotate {
    animation: ${spin} 2s linear infinite;
  }

  .success {
    width: 14px;
    height: 14px;
  }
`

export const StatusInfo = styled.span`
  font-size: 16px;
  color: ${({ status }) => (status === 'Concluído!' ? '#00b065' : '#0049c4')};
`

export const ParametrosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  box-sizing: border-box;
`

export const ParametrosCard = styled.div`
  display: flex;
  gap: 15px;
  height: 76px;
  width: 100%;
  padding: 17px 17px 17px 17px;
  background-color: #f0f0f0;
  box-sizing: border-box;

  border-radius: 10px;

  h3 {
    margin: 0 0 4px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: #838387;
    margin-top: 5px;
  }

  /* .checkbox {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #6d6d6d;
    background-color: white;
    appearance: none;
  } */

  /* .checkbox_active {
    appearance: none;
    margin: 0;
    padding: 0;
  } */
`

export const ParametrosDays = styled.div`
  position: relative;
  width: 3.375rem;
  box-sizing: border-box;

  > div {
    min-width: 2.875rem;
    max-height: 1.5625rem;
    border-radius: 5px;
    color: #fff;
    background-color: #000;
    position: absolute;
    top: -1.25rem;
    left: 5px;
    padding: 0 6px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: 0.25rem;
      padding: 4px 0;
      > span {
        font-size: 1rem;
        font-weight: 600;
      }

      > button {
        padding: 0px;
        display: flex;

        cursor: pointer;
        background-color: transparent;
        border: none;
      }

      .CounterParameter__Buttons--IconUp {
        transform: scaleY(-1);
      }
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  gap: 5px;
`

export const InputNumber = styled.div`
  display: flex;
  width: 40px;
  height: 21px;
  gap: 1.5px;
  background-color: black;

  border-radius: 4px;
  padding: 1px 2px 1px 7px;
  margin-top: -3px;

  > input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;

    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex-direction: column;

    > img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }

    > img:first-child {
      transform: rotate(180deg);
    }
  }
`

export const WhatsAppSection = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  box-sizing: border-box;

  > section {
    width: 336px;
    padding: 30px;
    background-color: #f0f0f0;
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    width: 336px;
    height: 339px;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    background-color: #f5f5f7;
    padding: 20px;
    border-radius: 0 0 20px 20px;

    > h4 {
      font-size: 16px;
    }

    > p {
      color: #838387;
    }
  }
`

export const WhatsAppInfo = styled.div`
  display: flex;
  flex-direction: column;

  padding: 28px;

  gap: 16px;

  border-radius: 20px;
  border: 1px solid #ccc;

  box-sizing: border-box;
  height: 417px;
`

export const WhatsAppBackground = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;

  gap: 16px;
`

export const WhatsAppInfoTitle = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  gap: 4px;

  > p {
    color: #838387;
  }
`

export const WhatsAppInfoContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
  height: 100%;
`
export const WhatsappStep = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > li {
    font-size: 16px;
  }
`
