import { useEffect, useState } from 'react'
import { AutomacaoConfig } from './AutomacaoSteps/AutomacaoConfig/AutomacaoConfig'
import { FunilSelect } from './AutomacaoSteps/FunilSelect/FunilSelect'
import './style.css'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import IconClose from '../../../../Home/assets/icon-close.svg'
import MenuTemplate from '../../../Automacoes/CreateAutomacao/MenuTemplate'
import { Alerts } from '../../../../../utils/alert'

const optionsStatus = [
  {
    label: 'Em Andamento',
    value: 'PENDENTE',
  },
  {
    label: 'Perdida',
    value: 'PERDIDA',
  },
  {
    label: 'Em Vistoria',
    value: 'CONVERTENDO',
  },
  {
    label: 'Convertida',
    value: 'CONVERTIDA',
  },
]

export function AutomacaoModal({
  setOpenModal,
  funis,
  automacao,
  setAutomacao,
  changeModal,
}) {
  const [step, setStep] = useState(1)
  const [columnsFunil, setColumnsFunil] = useState([])

  useEffect(() => {
    if (automacao.FUN_ID !== '') {
      buscarDadosFunil()
    }
  }, [automacao.FUN_ID])

  async function buscarDadosFunil() {
    try {
      const data = await parametrosRepository.buscarDadosFunil(automacao.FUN_ID)

      setColumnsFunil(
        data.map((item) => {
          return {
            value: item.KANBAN_NOME,
            label: item.KANBAN_NOME.toUpperCase(),
          }
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function handleAutomacao() {
    try {
      const data = await parametrosRepository.editarOuCriarAutomacao(automacao)

      setAutomacao({
        AUT_ID: '',
        AUT_NAME: '',
        FUN_ID: '',
        INFOS: [],
        AUT_TRIGGER: '',
      })
      setOpenModal(false)

      if (data.error) {
        Alerts.ERROR('Algo deu errado!')
      }
    } catch (error) {
      Alerts.ERROR('Algo deu errado!')
      console.log(error)
    }

    changeModal(true)
  }

  function renderSteps() {
    switch (step) {
      case 1:
        return (
          <FunilSelect
            funis={funis}
            automacao={automacao}
            setAutomacao={setAutomacao}
          />
        )
      case 2:
        return (
          <AutomacaoConfig
            columnsFunil={columnsFunil}
            optionsStatus={optionsStatus}
            automacao={automacao}
            setAutomacao={setAutomacao}
          />
        )
      default:
        return 'Passo inválido'
    }
  }

  const handleDisabled = () => {
    const { AUT_NAME, FUN_ID, AUT_TRIGGER, INFOS } = automacao

    if (step === 1) {
      return AUT_NAME === '' || FUN_ID === '' || AUT_TRIGGER === ''
    } else if (step === 2) {
      return INFOS.length <= 0
    }
  }

  return (
    <div className="bg_add Main__Fade">
      <div className="templates_create_container">
        <div className="modal__templates">
          <div className="close__modal">
            <img
              src={IconClose}
              alt="close"
              onClick={() => {
                setOpenModal(false)
                setColumnsFunil([])
                setAutomacao({
                  AUT_ID: '',
                  AUT_NAME: '',
                  FUN_ID: '',
                  INFOS: [],
                  AUT_TRIGGER: '',
                })
              }}
            />
          </div>

          <div className="templates__content">
            <MenuTemplate step={step} automacaoId={automacao.AUT_ID} />
            <div className="templates__box">
              {renderSteps()}
              <div className="btn__container">
                {step === 2 && (
                  <button className="btn_voltar" onClick={() => setStep(1)}>
                    Voltar
                  </button>
                )}
                <button
                  className="btn_avancar"
                  disabled={handleDisabled()}
                  onClick={() => {
                    step === 1 ? setStep(2) : handleAutomacao()
                  }}
                >
                  {step === 1 ? 'Avançar' : 'Salvar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
