import React, { useContext, useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
  BackgroundModal,
  ModalBoxResumo,
  ModalHeader,
  CloseLink,
  BodyResumo,
  ResumoGrid,
  GridWrapper,
  BtnSubmit,
  ResumoPlanos,
  ResumoParagrafo,
  ResumoTituloGrid,
  BottomResumo,
  BtnVoltar,
  CardTodosPlanos,
  CardTituloPlanos,
  CardValorPlano,
} from './style/styledModal'
import {
  CarrosselContainer,
  Carrossel,
  CardValorPlanoCarrossel,
} from './style/styledCarrossel'
import { FaPlus } from 'react-icons/fa'
import { RiCaravanFill } from 'react-icons/ri'
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdClose,
} from 'react-icons/io'
import { MdAttachMoney, MdGppGood } from 'react-icons/md'
import { CadastroAssociado } from '../../../../../context/CadastroASSContext'
import { CadastroVeiculo } from '../../../../../context/CadastroVEIContext'
import { CadastroPlano } from '../../../../../context/CadastroPLAContext'
import { KanbanContext } from '../../../../../context/KanbanContext'

import Vistoria from '../Data Table/Vistoria/Vistoria'

import { useNavigate } from 'react-router-dom'
import { handleClose } from '../../../../../services/handleClose'
import { oportunidadeRepository } from '../../../../../api/repositories/oportunidadesRepository'

const ModalResumo = () => {
  const { CLI_NOME, CLI_CPF, CLI_TELEFONE, CLI_EMAIL, setCLI_EMAIL } =
    useContext(CadastroAssociado)
  const {
    VEI_MARCA,
    VEI_MODELO,
    VEI_ANO,
    setZeroKm,
    implementos,
    setValorTotalImplementos,
  } = useContext(CadastroVeiculo)
  const { idPlano, valoresPlano, adicionais } = useContext(CadastroPlano)
  const { veiculoExiste } = useContext(KanbanContext)

  //setar itens
  useEffect(() => {
    if (adicionais.length > 0) {
      sessionStorage.setItem('ADICIONAIS', JSON.stringify(adicionais))
    }
  }, [])

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const VEI_PLACA = sessionStorage.getItem('VEI_PLACA')
  const VEI_VALOR_ACORDADO = sessionStorage.getItem('VEI_VALOR_ACORDADO')
  const PLANOS = sessionStorage.getItem('PLANOS')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const ADICIONAIS = sessionStorage.getItem('ADICIONAIS')
  let planosEscolhidos = JSON.parse(PLANOS)
  let adicionaisEscolhidos = ADICIONAIS ? JSON.parse(ADICIONAIS) : adicionais

  const veiValorAcordadoFormatado = parseFloat(VEI_VALOR_ACORDADO)

  const [botaoScroll, setBotaoScroll] = useState(false)
  const [modalVistoria, setModalVistoria] = useState(false)

  const navigate = useNavigate()

  const ref = useRef(null)
  const carrossel = useRef(null)

  const handleLeft = () => {
    carrossel.current.scrollLeft -= carrossel.current.offsetWidth
  }

  const handleRight = () => {
    carrossel.current.scrollLeft += carrossel.current.offsetWidth
  }

  const adicionaisItens = adicionaisEscolhidos.map((i) => {
    return i.ADICIONAIS
  })

  //scroll
  useEffect(() => {
    var element = document.querySelector('.sd')
    if (element) {
      element.addEventListener(
        'scroll',
        () => {
          var scrollTop = document.getElementById('sd').scrollTop
          var scrollHeight = document.getElementById('sd').scrollHeight // added
          var offsetHeight = document.getElementById('sd').offsetHeight
          var contentHeight = scrollHeight - offsetHeight // added
          if (contentHeight <= scrollTop) {
            // modified
            setBotaoScroll(true)
          } else {
            setBotaoScroll(false)
          }
        },
        false,
      )
    }
  }, [])

  async function criarOportunidade() {
    const VEN_ID = sessionStorage.getItem('VEN_ID')
    const CLI_ID = sessionStorage.getItem('CLI_ID')
    const VEI_ID = sessionStorage.getItem('VEI_ID')

    const DADOS = {
      VEN_ID,
      CLI_ID,
      VEI_ID,
      PLANOS_IDS: idPlano,
      ADESAO_VALORES: valoresPlano,
      ITENS_ADICIONAIS: adicionais,
    }

    try {
      const data = await oportunidadeRepository.criarOportunidade(DADOS)

      console.log('CRIANDO OPORTUNIDADE: ', data)
      if (veiculoExiste.veiculoBoolean) {
        // const { OPO_ID } = res.data;
        sessionStorage.removeItem('ADICIONAIS')
        setModalVistoria(true)
        // if (!closeModal) {
        //   navigate("/home/oportunidades");
        // }
      } else {
        navigate('/home/oportunidades')
        sessionStorage.removeItem('ADICIONAIS')
        sessionStorage.removeItem('VEI_SEGMENTO')
        sessionStorage.removeItem('PLACA')
        sessionStorage.removeItem('VEI_PLACA')
        sessionStorage.removeItem('VEI_VALOR_FIPE')
        sessionStorage.removeItem('CLI_ID')
        sessionStorage.removeItem('VEI_ID')
      }
    } catch (error) {
      console.log(error)
    }

    /*
      axios
      .post(
        'https://kaminocrm.com.br:7778/oportunidades/criar-oportunidade',
        DADOS,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
            },
        },
      )
      .then((res) => {
        console.log(res)
        if (veiculoExiste.veiculoBoolean) {
          // const { OPO_ID } = res.data;
          sessionStorage.removeItem('ADICIONAIS')
          setModalVistoria(true)
          // if (!closeModal) {
            //   navigate("/home/oportunidades");
            // }
            } else {
          navigate('/home/oportunidades')
        sessionStorage.removeItem('ADICIONAIS')
        sessionStorage.removeItem('VEI_SEGMENTO')
        sessionStorage.removeItem('PLACA')
        sessionStorage.removeItem('VEI_PLACA')
        sessionStorage.removeItem('VEI_VALOR_FIPE')
        sessionStorage.removeItem('CLI_ID')
        sessionStorage.removeItem('VEI_ID')
        }
        })
        .catch((err) => console.log(err))
        */
  }

  function sd() {
    var objDiv = document.getElementById(`sd`)
    objDiv.scrollTop = objDiv.scrollHeight
  }

  function su() {
    var objDiv = document.getElementById(`sd`)
    objDiv.scrollTop = 0
  }

  function ButtonScrollTop() {
    return (
      <BtnVoltar
        style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder' }}
        onClick={() => {
          su()
        }}
      >
        Voltar ao topo <IoIosArrowUp size={17} />
      </BtnVoltar>
    )
  }

  function ButtonScrollDown() {
    return (
      <BtnVoltar
        style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder' }}
        onClick={() => {
          sd()
        }}
      >
        Continuar lendo <IoIosArrowDown size={17} />
      </BtnVoltar>
    )
  }

  console.log(VEI_VALOR_ACORDADO)
  return (
    <BackgroundModal>
      {modalVistoria && (
        <Vistoria isVisible={setModalVistoria} OPO_ID={veiculoExiste.opoId} />
      )}
      <ModalBoxResumo>
        <ModalHeader>
          <h2>Informações do Proponente</h2>

          <CloseLink
            to="/home/oportunidades"
            onClick={() => {
              sessionStorage.removeItem('ADICIONAIS')
              setZeroKm(false)
              setValorTotalImplementos(0)
              handleClose()
            }}
          >
            <IoMdClose size={17} />
          </CloseLink>
        </ModalHeader>

        <BodyResumo className="sd" id="sd">
          <ResumoGrid>
            <div>
              <ResumoTituloGrid>Dados pessoais:</ResumoTituloGrid>
              <GridWrapper>
                <div>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >
                    <strong>Nome:</strong>
                  </span>
                  <ResumoParagrafo>{CLI_NOME.toUpperCase()}</ResumoParagrafo>

                  <span
                    style={{
                      display: 'block',
                      marginTop: '10px',
                    }}
                  >
                    <strong>Telefone:</strong>
                  </span>
                  <ResumoParagrafo>
                    {CLI_TELEFONE
                      ? CLI_TELEFONE.replace(/\D/g, '')
                          .replace(/^(\d{2})(\d)/g, '($1) $2')
                          .replace(/(\d)(\d{4})$/, '$1-$2')
                      : ''}
                  </ResumoParagrafo>
                </div>
                {/* <div>
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    <strong>Telefone:</strong>
                  </span>
                  <ResumoParagrafo>{CLI_TELEFONE}</ResumoParagrafo>

                  <span
                    style={{
                      display: "block",
                      marginTop: "10px",
                    }}
                  >
                    <strong>CPF:</strong>
                  </span>
                  <ResumoParagrafo>
                    {CLI_CPF ? CLI_CPF : "Não informado."}
                  </ResumoParagrafo>
                </div> */}
              </GridWrapper>
            </div>

            <div>
              <ResumoTituloGrid>Informações do Veículo:</ResumoTituloGrid>
              <GridWrapper>
                <div>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >
                    <strong>Placa:</strong>
                  </span>
                  <ResumoParagrafo style={{ textTransform: 'uppercase' }}>
                    {VEI_PLACA}
                  </ResumoParagrafo>

                  <span
                    style={{
                      display: 'block',
                      marginTop: '10px',
                    }}
                  >
                    <strong>Valor:</strong>
                  </span>

                  <ResumoParagrafo>
                    {' '}
                    {veiValorAcordadoFormatado.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    })}
                  </ResumoParagrafo>
                </div>
                <div>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >
                    <strong>Ano:</strong>
                  </span>
                  <ResumoParagrafo>{VEI_ANO}</ResumoParagrafo>

                  <span
                    style={{
                      display: 'block',
                      marginTop: '10px',
                    }}
                  >
                    <strong>Modelo:</strong>
                  </span>
                  <ResumoParagrafo>
                    {VEI_MARCA.toUpperCase()} / {VEI_MODELO.toUpperCase()}
                  </ResumoParagrafo>
                </div>
              </GridWrapper>
            </div>
          </ResumoGrid>

          <div style={{ marginBottom: '60px' }}>
            <ResumoTituloGrid>Planos escolhidos:</ResumoTituloGrid>
            <ResumoPlanos>
              {planosEscolhidos.map((i) => {
                // console.log(adicionaisEscolhidos.indexOf(i.plan_id));
                return (
                  <CardTodosPlanos>
                    <CardTituloPlanos>
                      <div>
                        <span id="titulo_plano">{i.plan_descricao}</span>
                        <span id="mensalidade" style={{ marginTop: '10px' }}>
                          {adicionaisEscolhidos
                            .map((i) => {
                              return i.PLAN_ID
                            })
                            .includes(i.plan_id)
                            ? adicionaisEscolhidos.map((i) => {
                                return Number(
                                  i.NOVA_MENSALIDADE,
                                ).toLocaleString('pt-BR', {
                                  currency: 'BRL',
                                  style: 'currency',
                                  minimumFractionDigits: 2,
                                })
                              })[
                                adicionaisEscolhidos
                                  .map((i) => {
                                    return i.PLAN_ID
                                  })
                                  .indexOf(i.plan_id)
                              ]
                            : i.plan_valor_mensalidade.toLocaleString('pt-BR', {
                                currency: 'BRL',
                                style: 'currency',
                                minimumFractionDigits: 2,
                              })}
                          <span
                            style={{
                              display: 'inline-block',
                              fontSize: '13px',
                            }}
                          >
                            /mês
                          </span>
                        </span>
                      </div>
                    </CardTituloPlanos>
                    <CardValorPlano>
                      <ul id={`sd`}>
                        {i.itens.map((itens) => {
                          return (
                            <li>
                              <MdGppGood />{' '}
                              {itens.item_descricao
                                ? itens.item_descricao[0].toUpperCase() +
                                  itens.item_descricao.slice(1).toLowerCase()
                                : 0}
                            </li>
                          )
                        })}

                        {
                          adicionaisItens.map((i) => {
                            return i.map((item) => {
                              return (
                                <li>
                                  <FaPlus size={12} />{' '}
                                  {item.item_nome ? item.item_nome : ''}
                                </li>
                              )
                            })
                          })[
                            adicionaisEscolhidos
                              .map((i) => {
                                return i.PLAN_ID
                              })
                              .indexOf(i.plan_id)
                          ]
                        }

                        {implementos?.map((i) => {
                          return (
                            <li>
                              <RiCaravanFill /> {i.IMP_TIPO}
                            </li>
                          )
                        })}
                        <li style={{ display: 'flex', alignItems: 'center' }}>
                          <MdAttachMoney size={18} /> Cota de participação 1:{' '}
                          {i ? i.plan_1_franquia : 0}%
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center' }}>
                          <MdAttachMoney size={18} /> Cota de participação 2:{' '}
                          {i ? i.plan_2_franquia : 0}%
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center' }}>
                          <MdAttachMoney size={18} /> Valor de filiação:{' '}
                          {i
                            ? i.plan_valor_adesao.toLocaleString('pt-BR', {
                                currency: 'BRL',
                                style: 'currency',
                                minimumFractionDigits: 2,
                              })
                            : 0}
                        </li>
                        <span ref={ref} />
                      </ul>
                    </CardValorPlano>
                  </CardTodosPlanos>
                )
              })}
            </ResumoPlanos>
          </div>
        </BodyResumo>

        {/* CARROSSEL */}

        <CarrosselContainer>
          <Carrossel ref={carrossel}>
            {planosEscolhidos.map((i) => {
              return (
                <CardTodosPlanos className="item">
                  <CardTituloPlanos>
                    <div>
                      <span id="titulo_plano">{i.plan_descricao}</span>
                      <span id="mensalidade" style={{ marginTop: '10px' }}>
                        {adicionaisEscolhidos
                          .map((i) => {
                            return i.PLAN_ID
                          })
                          .includes(i.plan_id)
                          ? adicionaisEscolhidos.map((i) => {
                              return Number(i.NOVA_MENSALIDADE).toLocaleString(
                                'pt-BR',
                                {
                                  currency: 'BRL',
                                  style: 'currency',
                                  minimumFractionDigits: 2,
                                },
                              )
                            })[
                              adicionaisEscolhidos
                                .map((i) => {
                                  return i.PLAN_ID
                                })
                                .indexOf(i.plan_id)
                            ]
                          : i.plan_valor_mensalidade.toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                              minimumFractionDigits: 2,
                            })}
                        <span
                          style={{ display: 'inline-block', fontSize: '13px' }}
                        >
                          /mês
                        </span>
                      </span>
                    </div>
                  </CardTituloPlanos>
                  <CardValorPlanoCarrossel>
                    <ul id={`sd`}>
                      {i.itens.map((itens) => {
                        return (
                          <li>
                            <MdGppGood />{' '}
                            {itens.item_descricao
                              ? itens.item_descricao[0].toUpperCase() +
                                itens.item_descricao.slice(1).toLowerCase()
                              : 0}
                          </li>
                        )
                      })}

                      {
                        adicionaisItens.map((i) => {
                          return i.map((item) => {
                            return (
                              <li>
                                <FaPlus size={12} />{' '}
                                {item.item_nome ? item.item_nome : ''}
                              </li>
                            )
                          })
                        })[
                          adicionaisEscolhidos
                            .map((i) => {
                              return i.PLAN_ID
                            })
                            .indexOf(i.plan_id)
                        ]
                      }

                      <li style={{ display: 'flex', alignItems: 'center' }}>
                        <MdAttachMoney size={18} /> Cota de participação 1:{' '}
                        {i ? i.plan_1_franquia : 0}%
                      </li>
                      <li style={{ display: 'flex', alignItems: 'center' }}>
                        <MdAttachMoney size={18} /> Cota de participação 2:{' '}
                        {i ? i.plan_2_franquia : 0}%
                      </li>
                      <li style={{ display: 'flex', alignItems: 'center' }}>
                        <MdAttachMoney size={18} /> Valor de filiação:{' '}
                        {i ? i.plan_valor_adesao : 0}
                      </li>
                      <span ref={ref} />
                    </ul>
                  </CardValorPlanoCarrossel>
                </CardTodosPlanos>
              )
            })}
          </Carrossel>

          <div className="buttons">
            <button onClick={() => handleLeft()}>
              <IoIosArrowBack size={19} />
            </button>
            <button onClick={() => handleRight()}>
              <IoIosArrowForward size={19} />
            </button>
          </div>
        </CarrosselContainer>

        <BottomResumo>
          {botaoScroll ? (
            <div className="btn-scroll">
              <ButtonScrollTop />
            </div>
          ) : (
            <div className="btn-scroll">
              <ButtonScrollDown />
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <BtnVoltar
              style={{ margin: '0 20px' }}
              onClick={() => navigate(-1)}
            >
              Voltar
            </BtnVoltar>
            <BtnSubmit type="submit" onClick={() => criarOportunidade()}>
              OK
            </BtnSubmit>
          </div>
        </BottomResumo>
      </ModalBoxResumo>
    </BackgroundModal>
  )
}

export default ModalResumo
