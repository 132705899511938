import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { SubMenuConfigSystem } from '../../styledParametrosContainer'
import { MdVisibility } from 'react-icons/md'
import { HiTrash } from 'react-icons/hi'
import { Fragment, useEffect, useState } from 'react'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import { FaFileUpload } from 'react-icons/fa'
import { LoadingNumbered } from '../../../../components/LoadingNumbered/LoadingNumbered'
import ModalImagem from '../../ModalImagem/ModalImagem'
import { Tab } from '@headlessui/react'
import { Parametros } from './Parametros'
import { Impressoes } from './Impressoes'
import { TabChange } from './styles'
import { WhatsApp } from './WhatsApp'

export function ConfigSistema({ setLoading }) {
  const [fileName, setFileName] = useState('Selecione...')
  const [vencerOpo, setVencerOpo] = useState(false)
  const [checkboxCapa, setCheckboxCapa] = useState(false)
  const [checkboxContraCapa, setCheckboxContraCapa] = useState(false)
  const [linkCapaExiste, setLinkCapaExiste] = useState('')
  const [linkContraCapaExiste, setLinkContraCapaExiste] = useState('')
  const [qtdeDiasVencimento, setQtdeDiasVencimento] = useState('')
  const [qntdMinimoDias, setQntdMinimoDias] = useState(false)
  const [capaEnviada, setCapaEnviada] = useState(null)
  const [contraCapaEnviada, setContraCapaEnviada] = useState(null)
  const [enableImg, setEnableImg] = useState(false)
  const [loadingNumbered, setLoadingNumbered] = useState(false)
  const [progress, setProgress] = useState(0)
  const [modalImagemCapa, setModalImagemCapa] = useState(false)
  const [modalImagemContraCapa, setModalImagemContraCapa] = useState(false)
  const [initialCheckboxCapa, setInitialCheckboxCapa] = useState(false)
  const [initialCheckboxContraCapa, setInitialCheckboxContraCapa] =
    useState(false)
  const [initialVencerOpoEnabled, setInitialVencerOpoEnabled] = useState(false)

  console.log('capa', checkboxCapa)
  console.log('contra', checkboxContraCapa)

  useEffect(() => {
    setLoading(true)
    buscarImagensSistema()
    buscarParametros()
  }, [])

  const DADOS_VENCIMENTO = {
    PAR_NOME: 'DATA_VENCIMENTO_OPORTUNIDADE',
    PAR_ATIVO: vencerOpo,
    PAR_CONFIG: qtdeDiasVencimento,
  }

  function addProgress(event) {
    let progressLoad = Math.round((event.loaded * 100) / event.total)
    setLoadingNumbered(true)
    setProgress(progressLoad)
    return progressLoad
  }

  function handleChangeDaysExpiration(e) {
    const { value } = e.target
    if (value.length >= 0) {
      setQtdeDiasVencimento(value)
      setQntdMinimoDias(true)
    }
    if (value <= 0) {
      setQntdMinimoDias(false)
    }
  }

  console.log('VENCER OPO: ', vencerOpo)

  async function buscarImagensSistema() {
    try {
      const data = await parametrosRepository.buscarImagensSistema()

      let CAPA_ASSOCIACAO = data.filter((value) => {
        return value.IMG_DESCRICAO === 'CAPA_ASSOCIACAO'
      })
      let CONTRA_CAPA_ASSOCIACAO = data.filter((value) => {
        return value.IMG_DESCRICAO === 'CONTRA_CAPA_ASSOCIACAO'
      })

      setInitialCheckboxCapa(CAPA_ASSOCIACAO[0].ATIVO)
      setInitialCheckboxContraCapa(CONTRA_CAPA_ASSOCIACAO[0].ATIVO)

      setCheckboxCapa(CAPA_ASSOCIACAO[0].ATIVO)
      setCheckboxContraCapa(CONTRA_CAPA_ASSOCIACAO[0].ATIVO)
      setLinkCapaExiste(CAPA_ASSOCIACAO[0].IMG_URL)
      setLinkContraCapaExiste(CONTRA_CAPA_ASSOCIACAO[0].IMG_URL)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarParametros() {
    try {
      const data = await parametrosRepository.buscarParametros()
      const api = data[0]
      setInitialVencerOpoEnabled(api.PAR_ATIVO)
      setVencerOpo(api.PAR_ATIVO)
      setQtdeDiasVencimento(api.PAR_CONFIG)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  function verificarAlteracoes() {
    return (
      checkboxCapa !== initialCheckboxCapa ||
      checkboxContraCapa !== initialCheckboxContraCapa ||
      vencerOpo !== initialVencerOpoEnabled ||
      enableImg ||
      qntdMinimoDias
    )
  }

  function pegarImagem(ev) {
    var img = ev.target.files[0]

    if (img.size <= 10 * 1024 * 1024) {
      setFileName(img.name)
      function imgB64(element) {
        var reader = new FileReader()
        reader.onloadend = function () {
          if (ev.target.name === 'capa-file') {
            setCapaEnviada(reader.result)
            console.log(reader.result)
          }
          if (ev.target.name === 'contra_capa-file') {
            setContraCapaEnviada(reader.result)
          }
        }
        reader.readAsDataURL(element)
      }
      imgB64(img)
      setEnableImg(true)
    } else {
      setFileName('Selecione o arquivo')
      setEnableImg(false)
    }
  }

  const enviarImagemCapa = async (apagar) => {
    console.log(capaEnviada)
    if (linkCapaExiste?.length > 0 && capaEnviada === null && !apagar) {
      console.log('TESTANDO PRA VER SE ENTRA AQUI')
      return
    } else if (capaEnviada || apagar) {
      try {
        await parametrosRepository.configurarCapaAssociacao(
          capaEnviada,
          checkboxCapa,
          addProgress,
        )

        setLoading(false)
        setTimeout(function () {
          setLoadingNumbered(false)
          window.location.reload()
        }, 1000)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  const enviarImagemContraCapa = async (apagar) => {
    if (
      linkContraCapaExiste?.length > 0 &&
      contraCapaEnviada === null &&
      !apagar
    ) {
      return
    } else if (contraCapaEnviada || apagar) {
      try {
        const data = await parametrosRepository.configurarContraCapaAssociacao(
          contraCapaEnviada,
          checkboxContraCapa,
          addProgress,
        )

        console.log('CONTRA CAPA ASSOCIACAO: ', data)
        setTimeout(function () {
          setLoadingNumbered(false)
          window.location.reload()
        }, 1000)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  const enviarVencimento = async () => {
    if (
      linkCapaExiste?.length > 0 &&
      capaEnviada === null &&
      linkContraCapaExiste?.length > 0 &&
      contraCapaEnviada === null
    ) {
      setLoading(true)
    }

    try {
      await parametrosRepository.atualizarParametros(DADOS_VENCIMENTO)

      if (
        linkCapaExiste?.length > 0 &&
        capaEnviada === null &&
        linkContraCapaExiste?.length > 0 &&
        contraCapaEnviada === null
      ) {
        setTimeout(function () {
          setLoading(false)
        }, 4000)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const enviarAlteracoes = async () => {
    await enviarVencimento()

    await enviarImagemCapa(!checkboxCapa)
    await enviarImagemContraCapa(!checkboxContraCapa)
  }

  console.log(linkCapaExiste)
  console.log(linkContraCapaExiste)

  return (
    <SubMenuConfigSystem>
      {loadingNumbered && <LoadingNumbered progress={progress} />}

      {modalImagemCapa ? (
        <ModalImagem closeModal={setModalImagemCapa} imagem={linkCapaExiste} />
      ) : (
        ''
      )}
      {modalImagemContraCapa ? (
        <ModalImagem
          closeModal={setModalImagemContraCapa}
          imagem={linkContraCapaExiste}
        />
      ) : (
        ''
      )}

      <div className="SettingUsers__Header">
        <div className="SideAdd">
          <div className="SideAdd__Title">
            <span>Parâmetros Gerais</span>
          </div>
          <div className="SideAdd__Subtitle">
            <span>
              Gerencie os parâmetros do sistema conforme suas preferências.
            </span>
          </div>
        </div>
      </div>

      <Tab.Group>
        <Tab.List as={Fragment}>
          <TabChange>
            <Tab as={Fragment}>
              {({ selected }) => (
                <span
                  style={{ outline: 'none' }}
                  className={selected ? 'isActive' : ''}
                >
                  Parâmetros
                </span>
              )}
            </Tab>
            <Tab as={Fragment} style={{ outline: 'none' }}>
              {({ selected }) => (
                <span
                  style={{ outline: 'none' }}
                  className={selected ? 'isActive' : ''}
                >
                  Impressões
                </span>
              )}
            </Tab>
            <Tab as={Fragment} style={{ outline: 'none' }}>
              {({ selected }) => (
                <span
                  style={{ outline: 'none' }}
                  className={selected ? 'isActive' : ''}
                >
                  WhatsApp
                </span>
              )}
            </Tab>
          </TabChange>
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <Parametros setLoad={setLoading} />
          </Tab.Panel>
          <Tab.Panel>
            <Impressoes
              setModalImagemCapa={setModalImagemCapa}
              setLinkCapaExiste={setLinkCapaExiste}
              setModalImagemContraCapa={setModalImagemContraCapa}
              setLinkContraCapaExiste={setLinkContraCapaExiste}
              setLoad={setLoading}
            />
          </Tab.Panel>
          <Tab.Panel>
            <WhatsApp setLoad={setLoading} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* <div className="oportunidades_geradas">
        <div className="oportunidades">
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Habilitar expiração de oportunidades pendentes"
              checked={vencerOpo}
              onChange={() => setVencerOpo((state) => !state)}
            />
          </FormGroup>
        </div>
        {vencerOpo && (
          <div className="oportunidades_menu">
            <label htmlFor="daysNumber">
              Será marcada como <span>PERDIDA</span> após{' '}
            </label>
            <input
              name="daysNumber"
              id="daysNumber"
              type="number"
              min={1}
              value={qtdeDiasVencimento}
              onChange={(e) => {
                const { value } = e.target
                if (value.length >= 0) {
                  setQtdeDiasVencimento(value)
                  setQntdMinimoDias(true)
                }
                if (value <= 0) {
                  setQntdMinimoDias(false)
                }
              }}
            />{' '}
            <label htmlFor="daysNumber">dias.</label>
          </div>
        )}
      </div>

      <hr style={{ width: '100%', background: '#E2E2E2' }} />

      <div>
        <h3>Impressão de oportunidades</h3>
        <div className="impressao_oportunidades_container">
          <FormGroup className="impressao_config">
            <FormControlLabel
              control={
                <Switch
                  checked={checkboxCapa}
                  onChange={() => setCheckboxCapa((state) => !state)}
                />
              }
              label="Imprimir capa"
            />
            {!checkboxCapa ? null : (
              <div>
                {linkCapaExiste ? (
                  <>
                    <MdVisibility
                      size={20}
                      onClick={() => {
                        setModalImagemCapa(true)
                      }}
                    />
                    <HiTrash
                      size={20}
                      onClick={() => {
                        setCapaEnviada('')
                        enviarImagemCapa(true)
                      }}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="capa-file" className="file-upload-label">
                      <span>{fileName}</span>
                      <FaFileUpload size={18} color="#0049c4" />
                    </label>

                    <input
                      id="capa-file"
                      name="capa-file"
                      className="input-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(ev) => pegarImagem(ev)}
                    />
                  </>
                )}
              </div>
            )}
          </FormGroup>
          <FormGroup className="impressao_config">
            <FormControlLabel
              control={
                <Switch
                  checked={checkboxContraCapa}
                  onChange={() => setCheckboxContraCapa((state) => !state)}
                />
              }
              label="Imprimir contra-capa"
            />
            {!checkboxContraCapa ? null : (
              <div>
                {linkContraCapaExiste ? (
                  <>
                    <MdVisibility
                      size={20}
                      onClick={() => setModalImagemContraCapa(true)}
                    />
                    <HiTrash
                      size={20}
                      onClick={() => {
                        setContraCapaEnviada(null)
                        enviarImagemContraCapa(true)
                      }}
                    />
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="contra_capa-file"
                      className="file-upload-label"
                    >
                      <span>{fileName}</span>
                      <FaFileUpload size={18} color="#0049c4" />
                    </label>

                    <input
                      id="contra_capa-file"
                      name="contra_capa-file"
                      className="input-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(ev) => pegarImagem(ev)}
                    />
                  </>
                )}
              </div>
            )}
          </FormGroup>
          <div className="save_button">
            <button
              onClick={() => {
                enviarAlteracoes()
              }}
              disabled={!verificarAlteracoes()}
            >
              Salvar
            </button>
          </div>
        </div>
      </div> */}
    </SubMenuConfigSystem>
  )
}
