import React, { useState, useEffect, useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import moment from 'moment'
import { FaUserAlt, FaCarSide } from 'react-icons/fa'
import { HiClipboard, HiClipboardCheck } from 'react-icons/hi'
import { BiInfoCircle } from 'react-icons/bi'
import { handleVistoriasId } from '../../../../../services/vistoriasId'
import iconInfo from '../../../assets/icon-info.svg'
import iconTask from '../../../assets/incon-task.svg'
import iconPending from '../../../assets/icon-attencion.svg'
// import { CadastroAssociado } from "../../../../../context/CadastroASSContext";

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { CadastroAssociado } from '../../../../../context/CadastroASSContext'

const DraggableItem = ({
  el,
  index,
  OptionsButton,
  setOpenModal,
  setDataResumo,
  mask,
}) => {
  const { setCLI_NOME, setCLI_CPF, setCLI_TELEFONE, setCLI_EMAIL } =
    useContext(CadastroAssociado)

  function idColor(element) {
    if (element.OPO_STATUS === 'PENDENTE') {
      return element.OPO_STATUS.toLowerCase()
    } else {
      return element.OPO_STATUS.toLowerCase()
    }
  }

  function capitalizeWords(text) {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  function statusInfo(element) {
    if (element.OPO_STATUS === 'CONVERTIDA') {
      return 'Convertida'
    }
    if (element.OPO_STATUS === 'CONVERTENDO') {
      return 'Em Vistoria'
    }
    if (element.OPO_STATUS === 'PERDIDA') {
      return 'Perdida'
    }
    if (element.OPO_STATUS === 'PENDENTE') {
      return 'Em Andamento'
    }
  }

  console.log(el)
  return (
    <Draggable key={el.CLI_ID} index={index} draggableId={`${el.CLI_ID}`}>
      {(provided, snapshot) => {
        // idColor(el)
        return (
          <>
            <div
              className={`card item ${snapshot.isDragging && 'dragging'}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(e) => {
                if (!e.target.closest('.btn-wrapper')) {
                  setOpenModal(true)
                  setDataResumo(el.OPO_ID)
                  setCLI_NOME(el.CLI_NOME)
                  setCLI_TELEFONE(el.CLI_TELEFONE)
                }
              }}
            >
              <div className="infos_kanban">
                <div style={{ display: 'flex' }}>
                  <span className="item" id="nome">
                    <p className="nome_span">{capitalizeWords(el.CLI_NOME)}</p>
                  </span>

                  <div className="btn-wrapper">
                    <div className="options-btn">
                      <OptionsButton el={el} />
                    </div>
                  </div>
                </div>

                <span className="item">
                  {el.CLI_TELEFONE ? mask(el.CLI_TELEFONE) : 'Não informado.'}
                </span>

                {el.VEI_PLACA && (
                  <span className="item">
                    {el.VEI_PLACA} | {el.VEI_ANO_MODELO}
                  </span>
                )}
                {el.VEI_MODELO && (
                  <span className="item" id="modelo">
                    {el.VEI_MODELO}
                  </span>
                )}
              </div>
              <div className="bottom">
                <span id="vendedor">
                  <span>{el.VEN_NOME}</span>
                </span>

                <div className="under-bottom">
                  <div>
                    <span id="data-criacao">
                      {moment(el.OPO_DATA_CADASTRO).format('DD/MM/YYYY')}
                    </span>

                    <div />
                  </div>

                  <div className="icons">
                    <div className="icons-info">
                      <Tippy
                        delay="275"
                        content={
                          el.ORG_DESCRICAO
                            ? `ORIGEM: ${el.ORG_DESCRICAO}`
                            : 'Sem origem definida'
                        }
                        placement="bottom"
                      >
                        <span id="origem">
                          <img src={iconInfo} alt="" />
                        </span>
                      </Tippy>

                      {el.TAREFAS && (
                        <Tippy
                          delay="275"
                          content={`${el.TAREFAS[0]}`}
                          placement="bottom"
                        >
                          <span id="tarefa">
                            <img src={iconTask} alt="" />
                          </span>
                        </Tippy>
                      )}
                      {el.OPO_STATUS === 'PENDENTE' &&
                        (!el.VEI_PLACA || !el.PLANOS) && (
                          <Tippy
                            delay="275"
                            content={`DADOS INCOMPLETOS`}
                            placement="bottom"
                          >
                            <span id="tarefa">
                              <img src={iconPending} alt="" />
                            </span>
                          </Tippy>
                        )}
                    </div>
                    <div className="status" id={idColor(el)}>
                      {el.OPO_STATUS && <span>{statusInfo(el)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Draggable>
  )
}

export default DraggableItem
